import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Gallery from '../../components/Gallery';

export default function Etchings() {
  const data = useStaticQuery(graphql`
    query EtchingsQuery {
      allCloudinaryMedia(
        filter: { tags: { eq: "etching" } }
        sort: { fields: public_id, order: ASC }
      ) {
        nodes {
          id
          secure_url
        }
      }
    }
  `);

  return (
    <Gallery
      galleryImages={data.allCloudinaryMedia.nodes}
      galleryName="Etchings"
      title="Etchings Gallery | Falls Monument | (920) 467-4939"
      description="Photo gallery of custom etchings on monuments that we have made for customers."
      urlPath="etchings"
    />
  );
}
